import { AiOutlineClose } from "react-icons/ai";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`;

export const StartWrapper = styled.div`
  position: fixed;
  left: 80%;
  top: 35%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const AssistantWrapper = styled.div<{ $isSignage: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) =>
    props.$isSignage ? "white" : "rgba(255, 255, 255, 0.6)"};
  animation: ${fadeIn} 0.5s ease-in-out;
`;

export const AssistantIconWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;

  & > div {
    // アイコンの位置を下に修正したい場合
    // position: absolute;
    // bottom: 0;

    width: 100%;
    height: 100%;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export const AssistantCloseButton = styled(AiOutlineClose).withConfig({
  shouldForwardProp: (prop) => !["isChatLogExpanded"].includes(prop),
})<{ isChatLogExpanded: boolean }>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  border: 1px solid #000;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  cursor: pointer;
  display: ${(props) => (props.isChatLogExpanded ? "none" : "block")};
`;

export const StartButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !["exhibitionThemeColor"].includes(prop),
})<{ exhibitionThemeColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: ${({ exhibitionThemeColor }) => `#${exhibitionThemeColor}`};
  border: 8px solid rgba(255, 255, 255, 1);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 48px !important;
  font-weight: 700;

  width: 420px;
  height: 420px;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const StartImage = styled.img`
  width: 164px;
  height: 164px;
  object-fit: contain;
`;

export const ButtonsWrapper = styled.div`
  position: fixed;
  top: 700px;
  left: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  gap: 30px;
`;

export const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #fff;
  border: 8px solid #3e3a3a;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 700;
  width: 325px;
  height: 325px;
  &:active {
    box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
  }
`;

export const RefreshIconWrapper = styled.span.withConfig({
  shouldForwardProp: (prop) => !["exhibitionThemeColor"].includes(prop),
})<{ exhibitionThemeColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 183px;
  height: 183px;
  background-color: ${({ exhibitionThemeColor }) => `#${exhibitionThemeColor}`};
  border-radius: 50%;
`;

export const RefreshImage = styled.img`
  width: 128px;
  height: 128px;
`;

export const SwitchImage = styled.img`
  margin-top: 30px;
  width: 325px;
  height: 325px;
  object-fit: contain;
  border-radius: 50%;
  cursor: pointer;
  &:active {
    box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
  }
`;
