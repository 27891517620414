import { useAtomValue } from "jotai";

import { ExhibitionAssistant } from "@/commands/ForExhibition/exhibitionAssistant";
import { ExhibitionCharacter } from "@/commands/ForExhibition/exhibitionCharacter";
import {
  AssistantWrapper,
  BackgroundWrapper,
  LogoWrapper,
} from "@/commands/ForExhibition/index.style";
import { clientConfigAtom } from "@/utils/atoms";

type Props = {
  imageLoaded: boolean;
  handleImageLoaded: () => void;
};

export default function ForExhibition({
  imageLoaded,
  handleImageLoaded,
}: Props) {
  const client = useAtomValue(clientConfigAtom);

  return (
    <AssistantWrapper isDarkMode={client.isDarkMode}>
      <BackgroundWrapper src={client.exhibitionBackground ?? ""}>
        <LogoWrapper
          isDarkMode={client.isDarkMode}
        >
          <img src={client.logoImage ?? ""} alt="" />
        </LogoWrapper>
      </BackgroundWrapper>
      <ExhibitionCharacter
        handleImageLoad={handleImageLoaded}
        imageLoaded={imageLoaded}
        characterPosition={client.characterPosition || 0}
      />
      <ExhibitionAssistant client={client} />
    </AssistantWrapper>
  );
}
