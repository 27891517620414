import { useCallback, useEffect, useState } from "react";

import {
  ButtonsWrapper,
  RefreshButton,
  RefreshIconWrapper,
  RefreshImage,
  StartWrapper,
  StartButton,
  StartImage,
  SwitchImage,
} from "@/commands/ForExhibition/exhibitionAssistant.style";
import { ExhibitionChatArea } from "@/commands/ForExhibition/exhibitionChatAreaComponent";
import { ExhibitionMicArea } from "@/commands/ForExhibition/exhibitionMicAreaComponent";
import { useMiibo } from "@/hooks/useMiibo";
import RefreshIcon from "@/public/forExhibition/refresh.svg";
import StartConversationIcon from "@/public/forExhibition/startConversation.svg";
import { ClientSchema } from "@/types/client";

type Props = {
  client: ClientSchema;
};

export function ExhibitionAssistant({ client }: Props) {
  const miiboHooks = useMiibo({
    code: client.id,
  });
  const [isStarted, setIsStarted] = useState<boolean>(false);
  useState<boolean>(false);
  const assistantEnd = useCallback(() => {
    setIsStarted(false);
    miiboHooks.reset();
  }, [miiboHooks]);

  const assistantSwitch = () => {
    if (client.switchUrl) {
      window.parent.location.href = client.switchUrl; // 修正: ?? 演算子を if 文に変更
    }
  };

  useEffect(() => {
    let lastInteractionTime = Date.now();
    const handleUserInteraction = () => {
      lastInteractionTime = Date.now();
    };

    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);

    const interval = setInterval(() => {
      if (!miiboHooks.isSpeaking) {
        if (Date.now() - lastInteractionTime > 300000) {
          if (isStarted) {
            assistantEnd();
          } else {
            window.location.reload();
          }
        } else {
          handleUserInteraction();
        }
      }
    }, 300000);

    return () => {
      clearInterval(interval);
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
    };
  }, [assistantEnd, isStarted, miiboHooks.isSpeaking]);

  if (!isStarted) {
    return (
      <StartWrapper>
        <StartButton
          exhibitionThemeColor={client.exhibitionThemeColor || "fff"}
          type="button"
          onClick={() => {
            setIsStarted(true);
            miiboHooks.startMiibo();
          }}
        >
          <StartImage src={StartConversationIcon} />
          会話を始める
        </StartButton>
      </StartWrapper>
    );
  }

  return (
    <>
      <ButtonsWrapper>
        <RefreshButton type="button" onClick={() => window.location.reload()}>
          <RefreshIconWrapper
            exhibitionThemeColor={client.exhibitionThemeColor || "fff"}
          >
            <RefreshImage src={RefreshIcon} />
          </RefreshIconWrapper>
        </RefreshButton>

        {client.switchUrl && <SwitchImage src={client.switchIcon} onClick={assistantSwitch}/>}
      </ButtonsWrapper>

      <ExhibitionMicArea
        miiboHooks={miiboHooks}
        exhibitionThemeColor={client.exhibitionThemeColor || "fff"}
      />
      <ExhibitionChatArea miiboHooks={miiboHooks} client={client} />
    </>
  );
}
