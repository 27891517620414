import styled, { keyframes } from "styled-components";

export const AssistantCommentWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 53%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const AssistantComment = styled.div`
  position: relative;
  color: #0d0d0d;
  padding: 135px 82px;
  margin: 2rem;
  width: 1582px;
  max-height: 1140px;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.84);
  border: 8px solid rgba(0, 0, 0, 1);
  border-radius: 80px;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  > * {
    font-size: 68px !important;
    font-weight: bold;
  }
`;

const ellipsisAnimation = keyframes`
  0% {
    content: "";
  }
  25% {
    content: "・";
  }
  50% {
    content: "・・";
  }
  75% {
    content: "・・・";
  }
  100% {
    content: "";
  }
`;

export const CommentLoading = styled.p`
  width: 200px;
  height: 120px;
  font-size: 68px !important;
  display: inline-block;
  color: #000;
  &:after {
    content: "・・・";
    animation: ${ellipsisAnimation} 1s infinite;
  }

  justify-content: left;
`;

export const UserSelectButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
`;

export const UserSelectButton = styled("button").withConfig({
  shouldForwardProp: (prop) =>
    !["isButtonVisible", "exhibitionThemeColor"].includes(prop),
})<{ isButtonVisible: boolean; exhibitionThemeColor: string }>`
  background-color: ${({ exhibitionThemeColor }) => `#${exhibitionThemeColor}`};
  border: 8px solid rgba(13, 13, 13, 1);
  color: #fff;
  border-radius: 100px;
  padding: 24px 36px;
  margin: 0.5rem 0.5rem;
  cursor: pointer;
  min-width: 70px;
  font-weight: 700;
  text-align: left;

  display: ${(props) => (props.isButtonVisible ? "block" : "none")};
  font-size: 68px !important;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;
