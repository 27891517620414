import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";
import styled from "styled-components";

import { MicroCmsImage } from "@/components/MicroCmsImage";
import {
  characterMotionAtom,
  characterSrcAtom,
  clientConfigAtom,
} from "@/utils/atoms";

const Container = styled.div<{ characterPosition: number }>`
  height: inherit;
  transform: ${({ characterPosition }) =>
    `translateY(${characterPosition}dvh) scale(1)`};
`;

type Props = {
  className?: string;
  imageLoaded: boolean;
  handleImageLoad: () => void;
  characterPosition: number;
};

export function ExhibitionCharacter({
  className,
  imageLoaded,
  handleImageLoad,
  characterPosition,
}: Props): JSX.Element {
  const client = useAtomValue(clientConfigAtom);
  const characterMotion = useAtomValue(characterMotionAtom);
  const characterSrc = useAtomValue(characterSrcAtom);
  const setCharacterSrc = useSetAtom(characterSrcAtom);

  useEffect(() => {
    const imageSources: {
      [key in typeof characterMotion]: string | undefined;
    } = {
      attention: client.character.attention,
      happy: client.character.happy,
      talk: client.character.talk,
      respectful: client.character.respectful,
      greeting: client.character.greeting,
      normal: client.character.normal,
    };
    const src =
      (characterMotion && characterMotion !== "normal"
        ? imageSources[characterMotion]
        : imageSources.normal) || "";

    setCharacterSrc(src);
  }, [characterMotion, client.character, setCharacterSrc]);

  const imageComponent = useMemo(
    () => (
      <MicroCmsImage
        src={characterSrc}
        format="webp"
        imageLoaded={imageLoaded}
        handleImageLoad={handleImageLoad}
      />
    ),
    [imageLoaded, handleImageLoad, characterSrc],
  );

  return (
    <Container characterPosition={characterPosition} className={className}>
      {imageComponent}
    </Container>
  );
}
