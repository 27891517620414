import { useMemo } from "react";
import styled from "styled-components";

import { Image } from "@/components/Image";

const StyledImage = styled(Image).withConfig({
  shouldForwardProp: (prop) => !["imageLoaded"].includes(prop),
})<{ imageLoaded: boolean }>`
  object-fit: contain;
  width: 100%;
  height: ${(props) => (props.imageLoaded ? "100%" : "0")};
  visibility: ${(props) => (props.imageLoaded ? "visible" : "hidden")};
`;

type Props = {
  className?: string;
  src: string;
  w?: number | string;
  h?: number;
  fit?: "clip" | "clamp" | "crop" | "fill" | "fillmax" | "max" | "scale";
  q?: number;
  dpr?: number;
  format?: "webp" | "jpg" | "png" | "json";
  imageLoaded: boolean;
  handleImageLoad: () => void;
  style?: React.CSSProperties;
};

export function MicroCmsImage({
  className,
  src,
  w,
  h,
  fit,
  q,
  dpr = 1,
  format = "webp",
  imageLoaded,
  handleImageLoad,
  style,
}: Props): JSX.Element {
  const imagePath = useMemo(() => {
    const params = new URLSearchParams(
      Object.fromEntries(
        Object.entries({
          w: w?.toString(),
          h: h?.toString(),
          fit,
          q: q?.toString(),
          dpr: dpr?.toString(),
          format,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        }).filter(([_, value]) => value !== undefined),
      ) as Record<string, string>,
    );
    return `${src}?${params.toString()}`;
  }, [dpr, fit, format, h, q, src, w]);
  return (
    <StyledImage
      className={className || ""}
      src={imagePath}
      imageLoaded={imageLoaded}
      onLoad={handleImageLoad}
      style={style}
    />
  );
}
